import { memo, ReactNode } from 'react';
import Box from '~/app/components/Box';

import Clickable, { ClickableProps } from '~/app/components/Clickable';
import Image from '~/app/components/Image';
import { YouTubeVideo } from '~/app/lib/songwhipApi/types';
import toSizedImageUrlNext from '~/app/lib/toSizedImageUrlNext';

interface VideoListItemProp extends Omit<ClickableProps, 'children'> {
  title?: string;
  image: YouTubeVideo['image'];
  link: string;
  aspect?: number;
  withHref?: boolean;
  children?: ReactNode;
  imageOpacity?: number;
  renderAfter?: () => ReactNode;
}

const VideoListItem = memo<VideoListItemProp>(
  ({
    image,
    link,
    aspect = 9 / 16,
    maxHeight,
    withHref = true,
    children,
    title,
    imageOpacity,
    onClick,
    data,
    renderAfter = () => {},
    ...boxProps
  }) => {
    const imgStyle = {
      width: '102%',
      height: '102%',
      left: '-1%',
      top: '-1%',
      right: '-1%',
      bottom: '-1%',
      filter: 'saturate(0.8)',
    };

    if (image.aspect === '4:3') {
      imgStyle.width = imgStyle.height = '132%';
      imgStyle.top = imgStyle.left = '-16%';
    }

    return (
      <Box tag="li" testId="videoListItem" {...boxProps}>
        <Clickable
          onClick={onClick}
          data={data}
          href={withHref ? link : undefined}
        >
          <Box
            positionRelative
            style={{
              border: 'solid 1px #333',
              borderRadius: '1.6rem',
              overflow: 'hidden',
            }}
          >
            <Image
              src={toSizedImageUrlNext({
                url: image.url,
                width: 700,
              })}
              className="image"
              borderRadius="1.6rem"
              aspect={aspect}
              pointerEvents="none"
              maxHeight={maxHeight}
              style={{
                background: '#111',
                opacity: imageOpacity,
              }}
              isLazy
              imgStyle={imgStyle}
            />
            {children}
          </Box>
          {renderAfter()}
        </Clickable>
      </Box>
    );
  }
);

export default VideoListItem;
