import React, { memo, useCallback, useState } from 'react';
import dynamic from 'next/dynamic';

import HorizontalScroller from '~/app/components/Scroller2/HorizontalScroller';
import useOpenExternalService from '~/app/lib/hooks/useOpenExternalService';
import Clickable, { ClickableOnClick } from '~/app/components/Clickable';
import useIsLargeScreen from '~/app/lib/hooks/useIsLargeScreen';
import { parseYoutubeVideoId } from '~/app/lib/youtube/utils';
import { Features } from '~/app/lib/store/session/types';
import useIsEnabled from '~/app/lib/hooks/useIsEnabled';
import PlayIcon from '~/app/components/Icon/PlayIcon';
import prettyWrap from '~/app/lib/utils/prettyWrap';
import Gradient from '~/app/components/Gradient';
import Text from '~/app/components/Text';
import { useI18n } from '~/app/lib/i18n';
import Box from '~/app/components/Box';

import { useRegisterNavItem } from '../../ItemPageNav';
import { PageSectionComponent } from '../types';
import SectionTitle from '../lib/SectionTitle';

import { VideosSectionItem, VideosSectionProps } from './types';
import VideoListItem from './VideoListItem';
import { usePageTheme } from '../../ItemPageEdit/addons/theme/PageThemeContext';

const YouTubeVideoModalDynamic = dynamic(
  () => import('~/app/components/YouTubeVideoModal')
);

const VideosSection: PageSectionComponent<VideosSectionProps> = ({
  title,
  items,
  sectionId,
  sectionIndex,
  navTitle = 'Videos',
}) => {
  const isLargeScreen = useIsLargeScreen();
  const openExternalService = useOpenExternalService();
  const { t } = useI18n();

  // youtube weren't happy with out embedded player so we've put it behind
  // a feature-flag until we decide what we want to do long-term
  const embeddedVideosEnabled = useIsEnabled(Features.EMBEDDED_VIDEOS);

  // Defining the default title here means we don't need to always use a preset.
  // It makes sense to use a preset if we want to define default content (eg. on artist page).
  // This means the component can be added, the layout customized and the props still feed
  // from the preset. Without the preset the props would have to be hard-coded into the custom
  // layout meaning the default videos will never update.
  if (title === undefined) {
    title = t('item.defaultVideosTitle');
  }

  const [activeItem, setActiveItem] = useState<{
    aspect?: number;
    youtubeVideoId: string;
  }>();

  const VideoList = isLargeScreen ? VideoListLargeScreen : VideoListSmallScreen;

  // don't render anything if there are no items
  if (!items?.length) {
    return null;
  }

  useRegisterNavItem({
    id: sectionId,
    text: navTitle,
    index: sectionIndex,
  });

  return (
    <Box testId="videos">
      <VideoList
        items={items}
        title={title}
        onVideoClick={useCallback(
          ({ data: item }: { data: VideosSectionItem }) => {
            const { isEmbeddable, link, aspect } = item;
            const youtubeVideoId = parseYoutubeVideoId(link);
            const shouldEmbed = isEmbeddable !== false;

            if (shouldEmbed && youtubeVideoId && embeddedVideosEnabled) {
              setActiveItem({
                aspect,
                youtubeVideoId,
              });

              return;
            }

            openExternalService(link);
          },
          []
        )}
      />
      {activeItem && (
        <YouTubeVideoModalDynamic
          videoId={activeItem.youtubeVideoId}
          aspect={activeItem.aspect}
          onClose={() => setActiveItem(undefined)}
        />
      )}
    </Box>
  );
};

const VideoListLargeScreen = memo<
  Pick<VideosSectionProps, 'items' | 'title'> & {
    onVideoClick: ClickableOnClick<VideosSectionItem>;
  }
>(({ items = [], title, onVideoClick }) => {
  const MAX_ITEMS_CONTRACTED = 6;
  const MAX_ITEMS_PER_ROW = 3;

  const [isExpanded, setIsExpanded] = useState(false);
  const { t } = useI18n();

  const hasMultiple = items.length > 1;
  const aspect = hasMultiple ? 3.4 / 3 : 9 / 16;
  const hasOverflowingItems = items.length > MAX_ITEMS_CONTRACTED;

  const totalItemsVisible = Math.max(
    items.length,
    items.length - MAX_ITEMS_CONTRACTED
  );

  const itemsPerRow = (() => {
    if (totalItemsVisible === 4) return 2;

    return Math.min(totalItemsVisible, MAX_ITEMS_PER_ROW);
  })();

  const itemWidthPercent = 100 / itemsPerRow;

  return (
    <div
      className="videos"
      data-testid="videoListLarge"
      style={{ padding: '0 1.2rem' }}
    >
      <div>
        {title && <SectionTitle padding="0 0 2.3rem" text={title} />}
        <Box
          flexRow
          flexWrap
          justifyCenter
          margin="-.5rem"
          positionRelative
          zIndex={0}
          tag="ul"
        >
          {items.map((item, index) => {
            const isOverflowItem = index + 1 > MAX_ITEMS_CONTRACTED;
            const isHidden = isOverflowItem && !isExpanded;

            return (
              <Box
                key={item.link}
                padding="0.5rem"
                positionRelative
                className="videoItem"
                style={{
                  display: isHidden ? 'none' : '',
                  flex: `1 0 ${itemWidthPercent}%`,
                }}
              >
                <VideoListItem
                  maxHeight={hasMultiple ? '20rem' : undefined}
                  withHref={false}
                  onClick={onVideoClick}
                  data={item}
                  {...item}
                  aspect={aspect}
                >
                  <PlayIcon
                    positionAbsolute
                    left="50%"
                    top="50%"
                    zIndex={2}
                    className="playIcon"
                    color="rgba(255,255,255,0.6)"
                    margin="-2rem"
                    style={{
                      filter: 'drop-shadow(0 0 1.2rem #000)',
                    }}
                    size="4rem"
                  />
                  <Gradient
                    positionAbsolute
                    top="60%"
                    bottom="-0.1%"
                    right="-0.1%"
                    left="-0.1%"
                    zIndex={1}
                    to="rgba(0,0,0,0.75)"
                    flexColumn
                    padding="0.8rem"
                    className="titleOverlay"
                    pointerEvents="none"
                  >
                    <Text
                      margin="auto 0 0"
                      size="1rem"
                      centered
                      lineClamp={2}
                      shadow="0px 0px 0.5em #000"
                      lineHeight="1.2em"
                    >
                      {prettyWrap(item.title)}
                    </Text>
                  </Gradient>
                </VideoListItem>
              </Box>
            );
          })}
        </Box>
      </div>
      {!isExpanded && hasOverflowingItems && (
        <Clickable
          tabIndex={0}
          onClick={() => setIsExpanded(true)}
          margin="2.2rem 0 0 0"
          testId="showMore"
          withHoverOpacityFrom={0.8}
        >
          <Text size="1.7rem" isBold centered>
            {t('app.actions.showMore')}
          </Text>
        </Clickable>
      )}
      <style jsx>{`
        .videos :global(.videoItem .image) {
          transition: opacity 200ms;
          opacity: 0.8;
        }

        .videos :global(.videoItem:hover .image) {
          opacity: 0.85;
        }

        .videos :global(.titleOverlay) {
          transform: translateY(10%);
          transition-duration: 500ms;
          transition-property: transform, opacity;
          will-change: transform;
          opacity: 0;
        }

        .videos :global(.videoItem:hover .titleOverlay) {
          transform: translateY(0%);
          transition-duration: 240ms;
          opacity: 1;
        }

        .videos :global(.playIcon) {
          transform: scale(100%);
        }

        .videos :global(.videoItem:hover .playIcon) {
          transform: scale(103%);
        }
      `}</style>
    </div>
  );
});

const VideoListSmallScreen = memo<
  Pick<VideosSectionProps, 'items' | 'title'> & {
    onVideoClick: ClickableOnClick<VideosSectionItem>;
  }
>(({ items = [], title, onVideoClick }) => {
  const hasMultiple = items.length > 1;
  const aspect = hasMultiple ? 9 / 13 : 9 / 16;
  const pageTheme = usePageTheme();

  return (
    <div
      data-testid="videoListSmall"
      style={{
        position: 'relative',
      }}
    >
      {title && <SectionTitle padding="0 0 2.3rem" text={title} />}
      <HorizontalScroller
        withSnapping
        tag="ul"
        gradientColor={pageTheme.backgroundColor}
        contentStyle={{
          padding: '0 .8rem',
        }}
        renderContent={useCallback(
          ({ snapItemStyle }) => {
            return items.map((item) => {
              return (
                <VideoListItem
                  {...item}
                  key={item.link}
                  padding="0 0.8rem"
                  // when only one video show full-width
                  width={hasMultiple ? '90vw' : '100%'}
                  style={snapItemStyle}
                  imageOpacity={0.85}
                  aspect={aspect}
                  onClick={onVideoClick}
                  withHref={false}
                  data={item}
                  renderAfter={() => {
                    return (
                      <Box fullWidth minWidth={0}>
                        <Text
                          withEllipsis
                          size="1.2rem"
                          margin="1rem 0 0"
                          color="#eee"
                          centered
                        >
                          {item.title}
                        </Text>
                      </Box>
                    );
                  }}
                >
                  <PlayIcon
                    positionAbsolute
                    left="50%"
                    top="50%"
                    zIndex={2}
                    className="playIcon"
                    color="rgba(255,255,255,0.6)"
                    margin="-2rem"
                    style={{
                      filter: 'drop-shadow(0 0 1.2rem #000)',
                    }}
                    size="5rem"
                  />
                </VideoListItem>
              );
            });
          },
          [items]
        )}
      />
    </div>
  );
});

export default VideosSection;
