export const parseYoutubeVideoId = (url: string) => {
  const LONG_VIDEO_URL_PATTERN = /(?:music\.)?youtube\.com\/watch\?v=([^&#]+)/;
  const SHORT_VIDEO_URL_PATTERN = /youtu.be\/([^?]+)/;
  const SHORTS_URL_PATTERN = /youtube\.com\/shorts\/([^?]+)/;

  let result: RegExpMatchArray | null;

  if ((result = url.match(LONG_VIDEO_URL_PATTERN))) {
    return result[1];
  }

  if ((result = url.match(SHORT_VIDEO_URL_PATTERN))) {
    return result[1];
  }

  if ((result = url.match(SHORTS_URL_PATTERN))) {
    return result[1];
  }
};
