import { State } from '~/app/lib/store/types';
import { useSelector } from '~/app/lib/store/redux';
import { selectIsEnabled } from '~/app/lib/store/session/selectors';
import { Features } from '~/app/lib/store/session/types';

/**
 * Check if a feature flag is enabled.
 */
const useIsEnabled = (feature: Features) =>
  useSelector((state: State) => selectIsEnabled(state, feature));

export default useIsEnabled;
